import React, { useMemo } from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import { getClientProps } from 'src/components/programmes/client-props';
import Programmes from 'src/components/programmes/programmes';
import SEO from 'src/components/seo';

const ProgrammesPage: React.FC = () => {
  const clientProps = useMemo(() => getClientProps()?.programmesPage ?? {}, []);
  return (
    <Layout>
      <SEO title="Programmes" />
      <PrivateRoute>
        <Programmes {...clientProps} />
      </PrivateRoute>
    </Layout>
  );
};

export default ProgrammesPage;
