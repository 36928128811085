import { ColumnDefinition } from 'src/components/admin-table/hooks';
import { OrgProfileReferrer } from 'src/components/organisations/types';
import ROUTES from 'src/utils/routes';

import * as styles from './scheme-courses-list.module.less';

const dateFormat = { dateStyle: 'medium' } as const;

export const getDefaultColumnsConfig = ({
  orgProfileReferrer,
  nameColumnHeaderTransKey,
  disabledColumns,
}: {
  orgProfileReferrer: OrgProfileReferrer;
  nameColumnHeaderTransKey: string;
  disabledColumns?: string[];
}) =>
  [
    {
      key: 'Scheme.Venue.Name',
      header: 'organisation',
      transformer: {
        type: 'link',
        args: {
          accessor: 'Scheme.Venue.Name',
          urlTemplate: `${ROUTES.MANAGE_ORGANISATIONS}/{{Scheme.Venue.ID}}?referrer=${orgProfileReferrer}`,
        },
      },
      sort: true,
    },
    {
      key: 'Scheme.Name',
      header: nameColumnHeaderTransKey,
      sort: false,
    },
    {
      key: 'WeekDays',
      header: 'days',
      transformer: {
        type: 'weekdays',
      },
      sort: false,
      columnToggle: {
        checked: true,
      },
    },
    {
      key: 'StartDate',
      header: 'start date',
      transformer: { type: 'date', args: { accessor: 'StartDate', dateFormat } },
      sort: true,
      columnToggle: {
        checked: true,
      },
    },
    {
      key: 'DefaultTime',
      header: 'start time',
      transformer: { type: 'time' },
      sort: false,
      columnToggle: {
        checked: true,
      },
    },
    {
      key: 'approver',
      header: 'county approver',
      transformer: {
        type: 'keyValueAsync',
        args: { accessor: 'Scheme.Venue.RegionID', accessorCtx: 'regionData' },
      },
      sort: false,
      columnToggle: {
        checked: true,
      },
    },
    {
      key: 'ExtendedStatus',
      header: 'status',
      transformer: { type: 'statusTag', args: { accessor: 'ExtendedStatus', type: 'ProgrammeStatusLabel' } },
      sort: false,
      columnToggle: {
        checked: true,
      },
    },
    {
      key: 'TotalSessions',
      header: 'sessions',
      widthClassName: styles.smallCells,
      sort: true,
      columnToggle: {
        checked: true,
      },
    },
    {
      key: 'TotalParticipants',
      header: 'participants',
      widthClassName: styles.smallCells,
      sort: true,
      columnToggle: {
        checked: true,
      },
    },
    {
      key: 'TotalAttendees',
      header: 'attendees',
      widthClassName: styles.smallCells,
      sort: true,
      columnToggle: {
        checked: true,
      },
    },
    {
      key: 'Scheme.Venue.County',
      header: 'county',
      widthClassName: styles.smallCells,
      columnToggle: {
        checked: false,
      },
      sort: false,
    },
    {
      key: 'Scheme.MinimumAge',
      header: 'min age',
      widthClassName: styles.smallCells,
      columnToggle: {
        checked: false,
      },
      sort: false,
    },
    {
      key: 'Scheme.MaximumAge',
      header: 'max age',
      widthClassName: styles.smallCells,
      columnToggle: {
        checked: false,
      },
      sort: false,
    },
    {
      key: 'LeadCoach',
      header: 'lead coach',
      widthClassName: styles.smallCells,
      columnToggle: {
        checked: false,
      },
      sort: false,
    },
  ].filter((col) => !disabledColumns || !disabledColumns.includes(col.key)) as ColumnDefinition[];
