// extracted by mini-css-extract-plugin
export var bodyBase = "scheme-courses-list-module--body-base--cc471 scheme-courses-list-module--site-font--dc63c";
export var bodyLarge = "scheme-courses-list-module--body-large--2adae scheme-courses-list-module--body-base--cc471 scheme-courses-list-module--site-font--dc63c";
export var bodyLargeBold = "scheme-courses-list-module--body-large-bold--7c0c9 scheme-courses-list-module--body-base--cc471 scheme-courses-list-module--site-font--dc63c";
export var bodyRegular = "scheme-courses-list-module--body-regular--d315b scheme-courses-list-module--body-base--cc471 scheme-courses-list-module--site-font--dc63c";
export var bodyRegularBold = "scheme-courses-list-module--body-regular-bold--7e029 scheme-courses-list-module--body-base--cc471 scheme-courses-list-module--site-font--dc63c";
export var bodySmall = "scheme-courses-list-module--body-small--79525 scheme-courses-list-module--body-base--cc471 scheme-courses-list-module--site-font--dc63c";
export var bodySmallBold = "scheme-courses-list-module--body-small-bold--ed87c scheme-courses-list-module--body-base--cc471 scheme-courses-list-module--site-font--dc63c";
export var borderTop = "scheme-courses-list-module--border-top--3bf8d";
export var breakWordContainer = "scheme-courses-list-module--break-word-container--31628";
export var buttonIconBase = "scheme-courses-list-module--button-icon-base--5a331";
export var clickLink = "scheme-courses-list-module--click-link--43d1c";
export var dropdownBase = "scheme-courses-list-module--dropdown-base--850c5";
export var dropdownSelectBase = "scheme-courses-list-module--dropdown-select-base--846c9 scheme-courses-list-module--text-input--c4b48";
export var flexCol = "scheme-courses-list-module--flex-col--f9c46";
export var formErrorMessage = "scheme-courses-list-module--form-error-message--dceb4";
export var h3 = "scheme-courses-list-module--h3--cdff3";
export var h4 = "scheme-courses-list-module--h4--91fa5";
export var hoverLink = "scheme-courses-list-module--hover-link--a9322";
export var hoverRow = "scheme-courses-list-module--hover-row--ef428";
export var membershipContainer = "scheme-courses-list-module--membership-container--13aa3 scheme-courses-list-module--flex-col--f9c46 scheme-courses-list-module--primary-border--53fc8";
export var membershipHeader = "scheme-courses-list-module--membership-header--6a32e";
export var membershipHeading = "scheme-courses-list-module--membership-heading--9cb56";
export var membershipLabel = "scheme-courses-list-module--membership-label--a4c23";
export var moreFiltersBorderClass = "scheme-courses-list-module--more-filters-border-class--6b251";
export var pageBg = "scheme-courses-list-module--page-bg--ff2fc";
export var pointer = "scheme-courses-list-module--pointer--02065";
export var primaryBorder = "scheme-courses-list-module--primary-border--53fc8";
export var programmeWrapper = "scheme-courses-list-module--programmeWrapper--ff7bc";
export var shadowBoxLight = "scheme-courses-list-module--shadow-box-light--7dc7e";
export var siteFont = "scheme-courses-list-module--site-font--dc63c";
export var slideDownAndFade = "scheme-courses-list-module--slideDownAndFade--959ac";
export var slideLeftAndFade = "scheme-courses-list-module--slideLeftAndFade--6536a";
export var slideRightAndFade = "scheme-courses-list-module--slideRightAndFade--7d862";
export var slideUpAndFade = "scheme-courses-list-module--slideUpAndFade--f956e";
export var smallCells = "scheme-courses-list-module--smallCells--337df";
export var statusDecoration = "scheme-courses-list-module--status-decoration--6337b";
export var textInput = "scheme-courses-list-module--text-input--c4b48";
export var textInverted = "scheme-courses-list-module--text-inverted--62748";
export var textMediumDark = "scheme-courses-list-module--text-medium-dark--84950";
export var tooltipFont = "scheme-courses-list-module--tooltipFont--b86da";
export var unstyledButton = "scheme-courses-list-module--unstyled-button--9dfde";