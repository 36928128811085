import React from 'react';

import SchemeCoursesList from 'src/components/scheme-courses-list/scheme-courses-list';
import { ConfigurableSchemeCoursesListProps } from 'src/components/scheme-courses-list/types';
import { SCHEME_SUBCATEGORY } from 'src/utils/constants/classic';
import { TABLE_IDS } from 'src/utils/constants/tables';

import { defaultColumnsConfig, defaultFiltersConfig, defaultTransKeys } from './config';

const Programmes = ({
  transKeys = defaultTransKeys,
  columnsConfig = defaultColumnsConfig,
  filtersConfig = defaultFiltersConfig,
}: ConfigurableSchemeCoursesListProps) => (
  <SchemeCoursesList
    transKeys={transKeys}
    schemeSubCategory={SCHEME_SUBCATEGORY.GROUP_COACHING}
    tableId={TABLE_IDS.PROGRAMMES_LIST}
    filtersConfig={filtersConfig}
    columnsConfig={columnsConfig}
  />
);

export default Programmes;
