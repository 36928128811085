import { OrgProfileReferrer } from 'src/components/organisations/types';
import { getDefaultColumnsConfig } from 'src/components/scheme-courses-list/config';

export const defaultTransKeys = {
  title: 'all programmes',
  queryError: 'organisationsModule.queryErrors.getOrgProgrammes',
};

export const defaultFiltersConfig = {
  hiddenStatusFilterOptions: [],
  categoryFilter: {
    schemeCourseTransKey: 'programme',
    defaultLabelTransKey: 'any programme',
  },
  regionFilter: {
    transKeyDefaultOption: 'any approver',
  },
};

export const defaultColumnsConfig = getDefaultColumnsConfig({
  orgProfileReferrer: OrgProfileReferrer.PROGRAMMES,
  nameColumnHeaderTransKey: 'programme',
});
